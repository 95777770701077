import { observable } from "mobx";
import axios from "axios";
import { Cookies } from "react-cookie";

class RestfulProvider {
  @observable cookies = new Cookies();
  constructor() {
    this.setCommonHeaders();
    this.apiUrl =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL;
  }
  setCommonHeaders = () => {
    if (this.cookies.get("user")) {
      axios.defaults.headers.common["Tenant-Id"] = this.cookies.get(
        "user"
      ).org_id;
    }
    axios.defaults.headers.common["Api-Key"] = process.env.REACT_APP_ARIIA_API_KEY;
    axios.defaults.headers.common["x-api-key"] = process.env.REACT_APP_ARIIA_API_KEY;
  };
  deleteHeaders = () => {
    delete axios.defaults.headers.common["Tenant-Id"];
    delete axios.defaults.headers.common["Api-Key"];
    delete axios.defaults.headers.common["x-api-key"];
  };
  get = url => {
    this.setCommonHeaders();
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.apiUrl}/${url}`)
        .then(res => resolve(res.data))
        .catch(error => {
          reject(error);
        });
    });
  };

  post = (url, data) => {
    if (url === "logout") {
      this.deleteHeaders();
    } else {
      this.setCommonHeaders();
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.apiUrl}/${url}`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  put = (url, data) => {
    this.setCommonHeaders();
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.apiUrl}/${url}`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  delete = url => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.apiUrl}/${url}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

export default new RestfulProvider();
