import { observable } from "mobx";
import InformationServices from "services/InformationServices";
import RequestServices from "services/RequestServices";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { message } from "antd";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import constants from "utils/constants";

class InformationStore {
  @observable allInformation = [];
  @observable btnLoading = false;
  @observable record = null;
  @observable showInformationModal = false;
  @observable showSubcontentModal = false;
  @observable imageFileList = [];
  @observable removedImages = [];
  @observable contentId = null;
  @observable videoUrl = "";
  @observable pdfFileList = [];
  @observable uploadLoading = false;
  @observable description = null;
  @observable editorState = EditorState.createEmpty();
  @observable categories = [];
  @observable filteredCategories = [];
  @observable subCategories = [];
  @observable showParkingFields = false;
  @observable parkingText = "";
  @observable parkingValue = "";
  @observable parkingData = [];
  @observable address = "";
  @observable geocode = null;
  @observable activeKey = null;

  constructor(globals) {
    this.globals = globals;
  }

  getInformation = async () => {
    try {
      this.btnLoading = true;
      const res = await InformationServices.getInformation(
        this.globals.propertyId
      );
      if (res && res.results) {
        this.allInformation = res.results;
        //set active tab
        this.onTabClick(
          localStorage.getItem("infoPageTab") ||
          (Boolean(res.results.length) && res.results[0].category_id)
        );
        localStorage.removeItem("infoPageTab");
      } else message.error(res.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  resetInformations = () => {
    this.allInformation = [];
  };
  onTabClick = key => {
    this.activeKey = key;
  };
  openInformationModal = (e, record) => {
    e.preventDefault();
    this.filterOutSelectedCategories(record);
    this.showInformationModal = true;
    this.record = record;
    this.videoUrl = record?.top_content_block?.[0]?.video;
    this.address = (record?.top_content_block?.[0]?.address) || "";
    this.geocode = (record?.top_content_block?.[0]?.geocode) || null;
    if (record?.top_content_block?.[0]?.images.length) {
      let imageFileList = record.top_content_block[0].images.map((image, index) => {
        return {
          uid: index,
          status: "done",
          url: image
        };
      });
      this.imageFileList = imageFileList;
    }
    if (record?.top_content_block?.[0]?.pdf?.length) {
      this.pdfFileList = [
        {
          uid: -1,
          status: "done",
          url: record.top_content_block[0].pdf,
          name: record.top_content_block[0].pdf.split("/")[5]
        }
      ];
    }

    this.parkingData = record?.top_content_block?.[0]?.parking_data;
    //editor datas
    if (record?.top_content_block?.[0]?.text_field) {
      this.description =  record.top_content_block[0].text_field;
      const contentBlock = htmlToDraft(record.top_content_block[0].text_field);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        this.editorState = EditorState.createWithContent(contentState);
      } else {
        this.editorState = EditorState.createEmpty();
      }
    }
  };

  openSubcontentModal = (e, record, contentId) => {
    e.preventDefault();
    this.showSubcontentModal = true;
    this.record = record;
    this.videoUrl = record && record.video;
    this.contentId = contentId;
    this.address = (record && record.address) || "";
    this.geocode = (record && record.geocode) || null;
    if (record && record.images && record.images.length) {
      let imageFileList = record.images.map((image, index) => {
        return {
          uid: index,
          status: "done",
          url: image
        };
      });
      this.imageFileList = imageFileList;
    }
    if (record && record.pdf && record.pdf.length) {
      this.pdfFileList = [
        {
          uid: -1,
          status: "done",
          url: record.pdf,
          name: record.pdf.split("/")[5]
        }
      ];
    }
    this.parkingData = record && record.parking_data;

    //editor data

    if (record && record.text_field) {
      this.description = record && record.text_field;

      const contentBlock = htmlToDraft(record.text_field);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        this.editorState = EditorState.createWithContent(contentState);
      } else {
        this.editorState = EditorState.createEmpty();
      }
    }
  };

  handleCancel = form => {
    this.record = null;
    this.showInformationModal = false;
    this.showSubcontentModal = false;
    this.imageFileList = [];
    this.removedImages = [];
    this.contentId = null;
    this.videoUrl = "";
    this.pdfFileList = [];
    this.editorState = EditorState.createEmpty();
    this.description = null;
    this.parkingData = [];
    this.address = "";
    this.geocode = null;
    form.resetFields();
  };

  handleInformationSubmit = (form, languages) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        this.btnLoading = true;
        console.log("Received values of form_: ", values);
        const formData = new window.FormData();
        let subCategorie = this.categories.filter(category => category._id === values.category_id);
        let has_subcategorie = subCategorie && subCategorie[0] && subCategorie[0].has_subcategories
        formData.append("has_subcategories", has_subcategorie || false)
        formData.append("category_id", values.category_id)
        formData.append("description", values.description)
        formData.append("title", values.title)
        formData.append("type", values.type || 0);
        if (values.type === 0) {
          //html editor
          formData.append("text_field", this.description || "");
        } else if (values.type === 1) {
          //map
          if (!this.address) this.geocode = null;
          formData.append("address", this.address);
          formData.append("geocode", this.geocode && JSON.stringify(this.geocode));
        } else if (values.type === 2 || values.type === 5) {
          //menu
          //pdf
          if (this.pdfFileList.length) {
            for (const file of this.pdfFileList) {
              file.originFileObj
                ? formData.append("pdf", file.originFileObj)
                : this.record && formData.append("pdf", this.record.pdf || this.record?.top_content_block?.[0]?.pdf);
            }
          }
          //send new images to upload
          if (this.imageFileList.length) {
            for (const file of this.imageFileList) {
              file.originFileObj && formData.append("images", file.originFileObj);
            }
          }
          //send array of old images
          this.record && formData.append("old_images", JSON.stringify(this.record?.top_content_block?.[0]?.images || []));
          //send array of removed images
          this.record && formData.append("removed_images", JSON.stringify(this.removedImages));
        } else if (values.type === 3) {
          //parking
          formData.append("parking_data", JSON.stringify(this.parkingData));
        } else if (values.type === 4) {
          //video
          if (values.video) {
            formData.append("video", values.video.file);
          } else if (values.video_url) {
            formData.append("video", values.video_url);
          } else {
            this.record && formData.append("video", this.record.video);
          }
        } else if (values.type === 6) {
          //target url
          formData.append("target", values.target || "");
        }
        try {
          values.title_lang = {};
          languages.map(lang => (values.title_lang[lang] = values[`title_${lang}`]));
          formData.append("title_lang", JSON.stringify(values.title_lang))
          values.description_lang = {};
          languages.map(lang => (values.description_lang[lang] = values[`description_${lang}`]));
          formData.append("description_lang", JSON.stringify(values.description_lang))
          if (!this.record) {
            const resp = await InformationServices.addInformation(values, this.globals.propertyId);
            if (resp && resp.results) {
              message.success("Content added successfully!");
              const allInformation = this.allInformation;
              allInformation.push(resp.results);
              this.allInformation = allInformation;
              this.handleCancel(form);
            } else message.error(resp.message || "Something went wrong!", 5);
          } else {
            const resp = await InformationServices.updateInformation(
              formData,
              this.globals.propertyId,
              this.record._id
            );
            if (resp && resp.results) {
              message.success("Content updated successfully!");
              const allInformation = this.allInformation.map(information => {
                if (information._id === this.record._id) {
                  information = resp.results;
                }
                return information;
              });
              this.allInformation = allInformation;
              this.handleCancel(form);
            } else message.error(resp.message || "Something went wrong!", 5);
          }
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
          message.error((error.response && error.response.data) || error.message, 5);
        }
      }
    });
  };

  onImageUploadChange = ({ fileList }) => {
    let files = fileList.map(file => {
      file.status = "done";
      return file;
    });
    this.imageFileList = files;
  };

  onImageRemove = file => {
    //remove image from fileList
    const index = this.imageFileList.indexOf(file);
    const newFileList = this.imageFileList.slice();
    newFileList.splice(index, 1);
    if (this.record) {
      //remove image from record(if already uploded image is removed)
      let record = this.record;
      let removedImages = this.removedImages.slice();
      if (record?.images?.lenght) {
        let newImages = record.images.map(img => {
          if (img !== this.imageFileList[index].url) return img;
          removedImages.push(img);
          return false;
        }).filter(Boolean);
        record.images = newImages;
        this.record = record;
        this.removedImages = removedImages;
      } else if (record?.top_content_block?.[0]?.images?.length) {
        let newImages = record.top_content_block[0].images.map(img => {
          if (img !== this.imageFileList[index].url) return img;
          removedImages.push(img);
          return false;
        }).filter(Boolean);
        record.top_content_block[0].images = newImages;
        this.record = record;
        this.removedImages = removedImages;
      }
    }
    this.imageFileList = newFileList;
  };

  getBase64(img, callback) {
    const reader = new window.FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleVideoChange = (info, form) => {
    this.videoUrl = "";
    this.uploadLoading = true;
    form.setFieldsValue({ video_url: "" }); //reset video URL input
    this.getBase64(info, imageUrl => {
      this.videoUrl = imageUrl;
      this.uploadLoading = false;
    });
    return false;
  };
  onVideoURLChange = (event, form) => {
    this.videoUrl = event.target.value;
    form.setFieldsValue({ video_url: { errors: [] }, video: null }); //reset errors in URL input and file from upload
  };
  handlePDFChange = ({ fileList }) => {
    let files = fileList.map(file => {
      file.status = "done";
      return file;
    });
    this.pdfFileList = files;
  };
  onPDFRemove = file => {
    //remove image from fileList
    const index = this.pdfFileList.indexOf(file);
    const newFileList = this.pdfFileList.slice();
    newFileList.splice(index, 1);
    this.pdfFileList = newFileList;
  };
  onEditorChange = event => {
    this.editorState = event;
    this.description = draftToHtml(convertToRaw(event.getCurrentContent()));
  };

  handleSubcontentSubmit = (form, languages) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const formData = new window.FormData();
        this.btnLoading = true;
        try {
          let headerLang = {};
          languages.map(lang => (headerLang[lang] = values[`header_${lang}`]));
          let sub_headerLang = {};

          languages.map(
            lang => (sub_headerLang[lang] = values[`sub_header_${lang}`])
          );
          formData.append("sub_header_lang", JSON.stringify(sub_headerLang));
          formData.append("header_lang", JSON.stringify(headerLang));
          formData.append("subContent", true); //send subContent=true if user want to update sub-content
          formData.append("header", values.header || "");
          formData.append("sub_header", values.sub_header || "");
          formData.append("sub_category_id", values.sub_category_id || "");
          formData.append("type", values.type || 0);
          if (this.record) {
            //edit sub-content
            formData.append("_id", this.record._id);
          }
          //send data based on content type
          if (values.type === 0) {
            //html editor
            formData.append("text_field", this.description || "");
          } else if (values.type === 1) {
            //map
            if (!this.address) {
              this.geocode = null;
            }
            formData.append("address", this.address);
            formData.append(
              "geocode",
              this.geocode && JSON.stringify(this.geocode)
            );
          } else if (values.type === 2 || values.type === 5) {
            //menu
            //pdf
            if (this.pdfFileList.length) {
              for (const file of this.pdfFileList) {
                file.originFileObj
                  ? formData.append("pdf", file.originFileObj)
                  : this.record && formData.append("pdf", this.record.pdf);
              }
            }
            //send new images to upload
            if (this.imageFileList.length) {
              for (const file of this.imageFileList) {
                file.originFileObj &&
                  formData.append("images", file.originFileObj);
              }
            }
            //send array of old images
            this.record &&
              formData.append("old_images", JSON.stringify(this.record.images));
            //send array of removed images
            this.record &&
              formData.append(
                "removed_images",
                JSON.stringify(this.removedImages)
              );
          } else if (values.type === 3) {
            //parking
            formData.append("parking_data", JSON.stringify(this.parkingData));
          } else if (values.type === 4) {
            //video
            if (values.video) {
              formData.append("video", values.video.file);
            } else if (values.video_url) {
              formData.append("video", values.video_url);
            } else {
              this.record && formData.append("video", this.record.video);
            }
          } else if (values.type === 6) {
            //target url
            formData.append("target", values.target || "");
          }

          //update sub-content in content
          const res = await InformationServices.updateInformation(
            formData,
            this.globals.propertyId,
            this.contentId
          );
          if (res && res.results) {
            message.success(
              `Sub-Content ${!this.record ? "added" : "updated"} successfully!`
            );
            const allInformation = this.allInformation.map(content => {
              if (content._id === this.contentId) {
                return res.results;
              }
              return content;
            });
            this.allInformation = allInformation;
            this.handleCancel(form);
          } else message.error(res.message || "Something went wrong!", 5);
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };

  getCategoryOfProperty = async () => {
    try {
      this.btnLoading = true;
      const res = await RequestServices.getCategoryOfProperty(
        this.globals.propertyId
      );
      if (res && res.results) {
        //filter out categories which are having fulfilment type "content" or having sub categories having fulfilment type "content"
        let categories = res.results.filter(cat => {
          if (cat.fulfilment_type === constants.FULFILMENT_TYPE.CONTENT) {
            return true;
          } else {
            return cat.sub_categories.some(
              sub => sub.fulfilment_type === constants.FULFILMENT_TYPE.CONTENT
            );
          }
        });
        this.categories = categories;
      } else message.error(res.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  filterOutSelectedCategories = record => {
    let value = [];
    if (record) {
      //for edit case, add all unselected id with current id
      value = this.categories.filter(cat => {
        if (cat._id === record.category_id) {
          return true;
        }
        return (
          this.allInformation.findIndex(i => i.category_id === cat._id) < 0
        );
      });
    } else {
      //for add case, add all unselected id
      value = this.categories.filter(
        cat => this.allInformation.findIndex(i => i.category_id === cat._id) < 0
      );
    }

    this.filteredCategories = value;
  };

  onCategorySelect = (id, option, form) => {
    //fetch new sub-categories list
    let filteredCategory = this.categories.filter(
      category => category._id === id
    );
    //filter our sub categories having fulfilemnt type "content"
    let subCategories =
      (filteredCategory.length &&
        filteredCategory[0].sub_categories.filter(
          sub => sub.fulfilment_type === constants.FULFILMENT_TYPE.CONTENT
        )) ||
      [];
    this.subCategories = subCategories;

    //set selected category to header on add case
    if (!this.record) {
      form && form.setFieldsValue({ title: option.props.children });
    }
  };

  onSubcategorySelect = (id, option, form) => {
    //set selected category to header on add case
    if (!this.record) {
      form && form.setFieldsValue({ header: option.props.children });
    }
  };

  deleteParkingData = record => {
    let parkingData = this.parkingData.filter(
      data => data._id !== record._id
    );
    this.parkingData = parkingData;
  };

  showParkingInpuFields = value => {
    this.showParkingFields = value;
    this.parkingText = "";
    this.parkingValue = "";
  };

  AddParkingData = form => {
    form.validateFields(async (err, values) => {
      if (!err) {
        let parkingData = this.parkingData || [];
        parkingData.push({
          text: this.parkingText,
          value: this.parkingValue
        });
        this.parkingData = parkingData;
        this.showParkingInpuFields(false);
      }
    });
  };

  handleparkingTextChange = e => {
    this.parkingText = e.target.value;
  };

  handleparkingValueChange = e => {
    this.parkingValue = e.target.value;
  };

  uploadImageCallBack = async file => {
    try {
      const formData = new window.FormData();
      formData.append("image", file);
      const res = await InformationServices.uploadImage(
        formData,
        this.globals.propertyId
      );
      if (res.results) {
        return {
          data: {
            link: res.results[0] || ""
          }
        };
      }
    } catch (error) {
      return error;
    }
  };

  setAddress = address => {
    this.address = address;
  };

  setGeocode = geocode => {
    this.geocode = geocode;
  };

  deleteContent = async (e, record) => {
    e.preventDefault();
    try {
      this.btnLoading = true;
      const res = await InformationServices.deleteInformation(
        this.globals.propertyId,
        record._id
      );
      if (res && res.results) {
        message.success(`Content deleted successfully!`);
        const allInformation = this.allInformation.filter(
          ad => ad._id !== record._id
        );
        this.allInformation = allInformation;
      } else message.error(res.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  deleteSubContent = async (e, record, informationId) => {
    e.preventDefault();
    try {
      this.btnLoading = true;
      let data = {
        subContent: true, //send subContent=true if user want to update sub-content
        delete: true, //send delete=true if user want to delete sub-content
        _id: record._id
      };
      const res = await InformationServices.updateInformation(
        data,
        this.globals.propertyId,
        informationId
      );
      if (res && res.results) {
        message.success(`Sub-Content deleted successfully!`);
        const allInformation = this.allInformation.map(info => {
          if (info._id === informationId) {
            return res.results;
          }
          return info;
        });
        this.allInformation = allInformation;
      } else message.error(res.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default InformationStore;
