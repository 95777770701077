import React, { Suspense, lazy, Component } from "react";
import Loader from "globals/components/loader";
import "./App.scss";

const Routes = lazy(() => import("./Routes"));

class App extends Component {
  componentDidMount() {
    var script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_PLACES_API_KEY
    }&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    return (
      <div className="App">
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
      </div>
    );
  }
}

export default App;
