import RestfulProvider from "globals/restfulProvider/RestfulProvider";

class InformationServices {
  getInformation = propertyId => {
    return RestfulProvider.get(`contents/${propertyId}`);
  };
  updateInformation = (data, propertyId, informationId) => {
    return RestfulProvider.put(`contents/${propertyId}/${informationId}`, data);
  };
  addInformation = (data, propertyId) => {
    return RestfulProvider.post(`contents/${propertyId}`, data);
  };
  uploadImage = (data, propertyId) => {
    return RestfulProvider.post(`upload/${propertyId}`, data);
  };
  deleteInformation = (propertyId, informationId) => {
    return RestfulProvider.delete(`contents/${propertyId}/${informationId}`);
  };
}
export default new InformationServices();
