import RestfulProvider from "globals/restfulProvider/RestfulProvider";

class AdvertisementServices {
  getAdvertisements = propertyId => {
    return RestfulProvider.get(`advertisements/${propertyId}`);
  };
  updateAdvertisement = (data, propertyId, adId) => {
    return RestfulProvider.put(`advertisements/${propertyId}/${adId}`, data);
  };
  addAdvertisement = (data, propertyId) => {
    return RestfulProvider.post(`advertisements/${propertyId}`, data);
  };
  deleteAdvertisement = (propertyId, adId) => {
    return RestfulProvider.delete(`advertisements/${propertyId}/${adId}`);
  };
}
export default new AdvertisementServices();
