import constants from "./constants";

export const serialize = obj => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (typeof obj[p] === "boolean" || obj[p] === 0 || obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
  }
  return str.join("&");
};

export const getUserRoles = user => {
  if (user && user.role === constants.ROLES.MANAGER) {
    return [
      {
        value: "manager",
        text: "Manager"
      }
    ];
  }
  return [
    {
      value: "manager",
      text: "Manager"
    },
    {
      value: "admin",
      text: "Admin"
    },
    {
      value: "request_manager",
      text: "Request Manager"
    },
    {
      value: "site_manager",
      text: "Site Manager"
    }
  ];
};

export const getOrgType = () => {
  return [
    {
      value: "hotel_group",
      text: "Hotel Group"
    },
    {
      value: "property_group",
      text: "Property Group"
    },
    {
      value: "care_home",
      text: "Care Home Group"
    },
    {
      value: "development_group",
      text: "Development Group"
    },
    {
      value: "myc",
      text: "My Community Organization"
    },
    {
      value: "pub_pass",
      text: "Pub Pass Group"
    }
  ];
};

export const getRoomTypes = () => {
  return [
    {
      value: "single",
      text: "Single"
    },
    {
      value: "suite",
      text: "Suite"
    }
  ];
};

export const getContentType = () => {
  return [
    {
      value: 0,
      title: "Text"
    },
    {
      value: 1,
      title: "Address"
    },
    {
      value: 2,
      title: "Images / PDF "
    },
    {
      value: 3,
      title: "List - Name / Value"
    },
    {
      value: 4,
      title: "Video"
    },
    {
      value: 5,
      title: "Images"
    },
    {
      value: 6,
      title: "Target URL"
    }
  ];
};

export const getCategoryTypes = () => {
  return [
    {
      value: 0,
      title: "No"
    },
    {
      value: 1,
      title: "Yes"
    }
  ];
};

export const getAdvertisementTypes = () => {
  return [
    {
      value: "int",
      title: "Internal"
    },
    {
      value: "ext",
      title: "External"
    },
    {
      value: "3rd",
      title: "3rd Party"
    }
  ];
};

export const getCampaignTypes = () => {
  return [
    {
      value: "impression",
      title: "Impression"
    },
    {
      value: "click",
      title: "Click"
    }
  ];
};

export const getFulfilmentTypes = () => {
  return [
    {
      value: "request",
      title: "Request Manager"
    },
    {
      value: "content",
      title: "Content Manager"
    }
  ];
};

export const getPriorities = () => {
  return [
    {
      value: "low",
      text: "LOW"
    },
    {
      value: "medium",
      text: "MEDIUM"
    },
    {
      value: "normal",
      text: "NORMAL"
    },
    {
      value: "high",
      text: "HIGH"
    }
  ];
};

export const getStatus = () => {
  return [
    {
      value: "in_progress",
      text: "IN PROGRESS"
    },
    {
      value: "completed",
      text: "COMPLETED"
    },
    {
      value: "pending",
      text: "PENDING"
    },
    {
      value: "open",
      text: "OPEN"
    }
  ];
};

export const getRatings = () => {
  return [
    {
      value: "1",
      title: "1"
    },
    {
      value: "2",
      title: "2"
    },
    {
      value: "3",
      title: "3"
    },
    {
      value: "4",
      title: "4"
    },
    {
      value: "5",
      title: "5"
    }
  ];
};

export const getLanguages = () => {
  return [
    {
      value: "en-GB",
      title: "en-GB"
    },
    {
      value: "en",
      title: "en"
    },
    {
      value: "es",
      title: "es"
    },
    {
      value: "nl",
      title: "nl"
    }
  ];
};

export const getCurrencies = () => {
  return [
    {
      value: "GBP",
      title: "GBP"
    },
    {
      value: "pound",
      title: "£ (Pound)"
    },
    {
      value: "euro",
      title: "€ (Euro)"
    }
  ];
};

export const getFulfilmentTemplate = () => {
  return [
    {
      value: true,
      title: "Yes"
    },
    {
      value: false,
      title: "No"
    }
  ];
};
