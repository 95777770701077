import { observable } from "mobx";
import _formatMessage from "format-message";

export default class LocaleStore {
  @observable _locale = null; // the locale value
  @observable translations = {};

  constructor(defaultLocale, translations) {
    this.translations = translations;
    this.value = defaultLocale;
  }

  get value() {
    return this._locale;
  }

  set value(value) {
    this._locale = value;
  }

  get messages() {
    return this.translations[this.value];
  }

  formatMessage = (id, values) => {
    if (!(id in this.messages)) {
      console.warn("Id not found in intl list: " + id);
      return id;
    }
    return _formatMessage(this.messages[id], values);
  };
}
