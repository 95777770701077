import { observable } from "mobx";
import AdvertisementServices from "services/AdvertisementServices";
import { message } from "antd";
import moment from "moment";

class AdvertisementsStore {
  @observable allAdvertisements = [];
  @observable loading = false;
  @observable record = null;
  @observable showCampaignModal = false;
  @observable imageUrl = "";
  constructor(globals) {
    this.globals = globals;
  }

  getAdvertisements = async () => {
    try {
      this.loading = true;
      const res = await AdvertisementServices.getAdvertisements(
        this.globals.propertyId
      );
      if (res && res.results) {
        this.allAdvertisements = res.results;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  resetAdvertisements = () => {
    this.allAdvertisements = [];
  };
  openCampaignModal = (e, record) => {
    e.preventDefault();
    this.imageUrl = record && record.image;
    this.showCampaignModal = true;
    this.record = record;
  };

  handleCancel = form => {
    this.record = null;
    this.imageUrl = "";
    this.showCampaignModal = false;
    form.resetFields();
  };
  getBase64(img, callback) {
    const reader = new window.FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleImageChange = info => {
    this.imageUrl = "";
    this.getBase64(info, imageUrl => {
      this.imageUrl = imageUrl;
    });
    return false;
  };

  handleSubmit = form => {
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const formData = new window.FormData();
        try {
          this.loading = true;
          if (values.image) {
            formData.append("image", values.image.file);
          } else {
            this.record && formData.append("image", this.record.image || "");
          }
          formData.append(
            "start_date",
            moment(values.start_date)
              .startOf("day")
              .toISOString()
          );
          formData.append(
            "end_date",
            moment(values.end_date)
              .endOf("day")
              .toISOString()
          );
          formData.append("name", values.name);
          formData.append("type", values.type);
          formData.append("ad_type", values.ad_type);
          formData.append("count", values.count);
          formData.append("text", values.text || "");
          formData.append(
            "active",
            values.active === undefined ? true : values.active
          );
          formData.append("link", values.link);

          if (!this.record) {
            const resp = await AdvertisementServices.addAdvertisement(
              formData,
              this.globals.propertyId
            );
            if (resp && resp.results) {
              message.success("Campaign added successfully!");
              const allAdvertisements = this.allAdvertisements;
              allAdvertisements.push(resp.results);
              this.allAdvertisements = [...allAdvertisements];
              this.handleCancel(form);
            } else message.error(resp.message || "Something went wrong!", 5);
            this.loading = false;
          } else {
            const resp = await AdvertisementServices.updateAdvertisement(
              formData,
              this.globals.propertyId,
              this.record._id
            );
            if (resp && resp.results) {
              message.success("Campaign details updated successfully!");
              const allAdvertisements = this.allAdvertisements.map(
                advertisement => {
                  if (advertisement._id === this.record._id) {
                    advertisement = resp.results;
                  }
                  return advertisement;
                }
              );
              this.allAdvertisements = allAdvertisements;
              this.handleCancel(form);
            } else message.error(resp.message || "Something went wrong!", 5);
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };
  deleteCampaign = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const res = await AdvertisementServices.deleteAdvertisement(
        this.globals.propertyId,
        record._id
      );
      if (res && res.results) {
        message.success(`Campaign deleted successfully!`);
        const allAdvertisements = this.allAdvertisements.filter(
          ad => ad._id !== record._id
        );
        this.allAdvertisements = allAdvertisements;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default AdvertisementsStore;
