import { observable } from "mobx";
import ConfigureGroupsServices from "services/ConfigureGroupsServices";
import { message } from "antd";

class ConfigureGroupsStore {
  @observable groups = [];
  @observable activeKey = null;
  @observable showGroupModal = false;
  @observable record = null;
  @observable showCategoryModal = false;
  @observable showSubCategoryModal = false;
  @observable groupId = null;
  @observable categoryId = null;
  @observable loading = false;
  @observable imageUrl = "";
  @observable subImageUrl = "";
  @observable iconImg = "";
  @observable popconfirmVisible = false;
  @observable hasSubcategories = false;
  @observable fulfillmentLoading = false;
  @observable fulfillment = [];
  @observable showFulfillmentModal = false;
  @observable mapping = [];

  constructor(globals) {
    this.globals = globals;
  }
  onTabChange = activeKey => {
    this.activeKey = activeKey;
  };

  getGroups = async () => {
    try {
      this.loading = true;
      const res = await ConfigureGroupsServices.getGroups(
        this.globals.propertyId,
        {
          allData: true
        }
      );
      if (res && res.results) {
        this.groups = res.results;
        this.activeKey = (this.groups.length && this.groups[0]._id) || null;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  resetGroups = () => {
    this.groups = [];
    this.fulfillment = [];
    this.mapping = [];
  };
  onSwitchChange = async (checked, groupId) => {
    try {
      const res = await ConfigureGroupsServices.updateGroup(
        { enabled: checked },
        this.globals.propertyId,
        groupId
      );
      if (res && res.results) {
        const groups = this.groups.map(group => {
          if (group._id === groupId) {
            group.enabled = checked;
          }
          return group;
        });
        this.groups = groups;
      } else message.error(res.message || "Something went wrong!", 5);
    } catch (error) {
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  openGroupModal = (e, record) => {
    e.preventDefault();
    this.showGroupModal = true;
    this.record = record;
  };

  handleCancel = form => {
    this.record = null;
    this.showGroupModal = false;
    this.showCategoryModal = false;
    this.showSubCategoryModal = false;
    this.groupId = null;
    this.categoryId = null;
    form.resetFields();
    this.imageUrl = "";
    this.subImageUrl = "";
    this.iconImg = "";
    this.hasSubcategories = false;
    this.showFulfillmentModal = false;
  };

  handleGroupSubmit = (form, languages) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        this.loading = true;
        console.log("Received values of form: ", values);
        const formData = new window.FormData();
        try {
          if (values.icon_img) {
            formData.append("icon_img", values.icon_img.file);
          } else {
            this.record &&
              formData.append("icon_img", this.record.icon_img || "");
          }
          let titleLang = {};
          languages.map(lang => (titleLang[lang] = values[`title_${lang}`]));

          formData.append("title_lang", JSON.stringify(titleLang));
          formData.append("enabled", values.enabled);
          formData.append("title", values.title);
          formData.append("position", values.position);

          if (!this.record) {
            //add group
            const res = await ConfigureGroupsServices.addGroup(
              formData,
              this.globals.propertyId
            );
            if (res && res.results) {
              message.success("Group added successfully!");
              const groups = this.groups;
              groups.push(res.results);
              // sort by position
              this.groups = groups.sort(function(a, b) {
                return a.position - b.position;
              });
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.loading = false;
          } else {
            //edit group
            const res = await ConfigureGroupsServices.updateGroup(
              formData,
              this.globals.propertyId,
              this.record._id
            );
            if (res && res.results) {
              message.success("Group updated successfully!");
              const groups = this.groups.map(group => {
                if (group._id === this.record._id) {
                  delete res.results.categories;
                  return { ...group, ...res.results }; //copy updated group data from res and copy category data from old group data
                }
                return group;
              });
              // sort by position
              groups.sort(function(a, b) {
                return a.position - b.position;
              });
              this.groups = groups;
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };

  openCategoryModal = (e, record, groupId) => {
    e.preventDefault();
    this.showCategoryModal = true;
    this.record = record;
    this.groupId = groupId;
    this.hasSubcategories = (record && record.has_subcategories) || false;
  };
  handleCategorySubmit = (form, languages) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        this.loading = true;
        console.log("Received values of form: ", values);
        const formData = new window.FormData();
        try {
          if (values.tablet_image) {
            formData.append("tablet_image", values.tablet_image.file);
          } else {
            this.record &&
              formData.append("tablet_image", this.record.tablet_image || "");
          }
          // if (values.mobile_image) {
          //   formData.append("mobile_image", values.mobile_image.file);
          // } else {
          //   this.record &&
          //     formData.append("mobile_image", this.record.mobile_image || "");
          // }

          let titleLang = {};
          languages.map(lang => (titleLang[lang] = values[`title_${lang}`]));
          let descriptionLang = {};
          languages.map(
            lang => (descriptionLang[lang] = values[`description_${lang}`])
          );

          formData.append("description_lang", JSON.stringify(descriptionLang));
          formData.append("title_lang", JSON.stringify(titleLang));
          formData.append("property_id", this.globals.propertyId);
          formData.append("title", values.title);
          formData.append("description", values.description);
          formData.append("position", values.position);
          formData.append("has_subcategories", this.hasSubcategories);
          formData.append("fulfilment_type", values.fulfilment_type || "");
          formData.append("get_feedback", values.get_feedback || false);
          if (!this.record) {
            //add category
            const res = await ConfigureGroupsServices.addCategory(
              formData,
              this.groupId
            );
            if (res && res.results) {
              message.success("Category added successfully!");
              const groups = this.groups.map(group => {
                if (group._id === this.groupId) {
                  let categories = group.categories;
                  categories.push(res.results);
                  group.categories = categories.sort(function(a, b) {
                    // sort by position
                    return a.position - b.position;
                  });
                }
                return group;
              });
              this.groups = groups;
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.loading = false;
          } else {
            //edit category
            const res = await ConfigureGroupsServices.updateCategory(
              formData,
              this.record.group_id,
              this.record._id
            );
            if (res && res.results) {
              message.success("Category updated successfully!");
              const groups = this.groups.map(group => {
                if (group._id === this.record.group_id) {
                  const categories = group.categories.map(category => {
                    if (category._id === this.record._id) {
                      return res.results;
                    }
                    return category;
                  });
                  // sort by position
                  categories.sort(function(a, b) {
                    return a.position - b.position;
                  });
                  group.categories = categories;
                }
                return group;
              });
              this.groups = groups;
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };

  openSubCategoryModal = (e, record, groupId, categoryId) => {
    e.preventDefault();
    this.showSubCategoryModal = true;
    this.record = record;
    this.groupId = groupId;
    this.categoryId = categoryId;
  };
  handleSubCategorySubmit = (form, languages) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        this.loading = true;
        console.log("Received values of form: ", values);
        const formData = new window.FormData();
        try {
          formData.append("subCategory", true); //send subCategory=true if user want to update sub-categories
          if (values.tablet_image) {
            formData.append("tablet_image", values.tablet_image.file);
          } else {
            this.record &&
              formData.append("tablet_image", this.record.tablet_image || "");
          }
          // values.tablet_image &&
          //   formData.append("mobile_image", values.tablet_image.file);
          let titleLang = {};
          languages.map(lang => (titleLang[lang] = values[`title_${lang}`]));
          let descriptionLang = {};
          languages.map(
            lang => (descriptionLang[lang] = values[`description_${lang}`])
          );

          formData.append("description_lang", JSON.stringify(descriptionLang));
          formData.append("title_lang", JSON.stringify(titleLang));
          formData.append("property_id", this.globals.propertyId);
          formData.append("title", values.title);
          formData.append("description", values.description);
          formData.append("position", values.position);
          formData.append("fulfilment_type", values.fulfilment_type);
          formData.append("get_feedback", values.get_feedback || false);
          if (this.record) {
            //edit sub-category
            formData.append("_id", this.record._id);
          }
          //update sub-category in category
          const res = await ConfigureGroupsServices.updateCategory(
            formData,
            this.groupId,
            this.categoryId
          );
          if (res && res.results) {
            message.success(
              `Sub-Category ${!this.record ? "added" : "updated"} successfully!`
            );
            const groups = this.groups.map(group => {
              if (group._id === this.groupId) {
                const categories = group.categories.map(category => {
                  if (category._id === this.categoryId) {
                    res.results.sub_categories.sort(function(a, b) {
                      return a.position - b.position;
                    });
                    return res.results;
                  }
                  return category;
                });
                group.categories = categories;
              }
              return group;
            });
            this.groups = groups;
            this.handleCancel(form);
          } else message.error(res.message || "Something went wrong!", 5);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };

  getBase64(img, callback) {
    const reader = new window.FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleImageChange = info => {
    this.getBase64(info, imageUrl => {
      this.imageUrl = imageUrl;
    });
    return false;
  };

  handleSubImageChange = info => {
    this.getBase64(info, imageUrl => {
      this.subImageUrl = imageUrl;
    });
    return false;
  };
  handleIconImgChange = info => {
    this.getBase64(info, imageUrl => {
      this.iconImg = imageUrl;
    });
    return false;
  };
  onCheckChange = (e, form) => {
    this.hasSubcategories = e.target.checked;
    form.resetFields(["fulfilment_type"]);
  };
  confirm = () => {
    this.hasSubcategories = false;
    this.popconfirmVisible = false;
  };

  cancel = () => {
    this.hasSubcategories = true;
    this.popconfirmVisible = false;
  };

  handleVisibleChange = popconfirmVisible => {
    if (!popconfirmVisible) {
      this.popconfirmVisible = popconfirmVisible;
      return;
    }
    // Determining condition before show the popconfirm.
    console.log(this.hasSubcategories);
    if (!this.hasSubcategories || !this.record || this.record.fulfilment_type) {
      //skip if hasSubcategories = false, add category case, edit category(fulfilment is selected)
      this.confirm(); // next step
    } else {
      this.popconfirmVisible = popconfirmVisible; // show the popconfirm
    }
  };
  deleteCategory = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const res = await ConfigureGroupsServices.deleteCategory(
        record.group_id,
        record._id
      );
      if (res && res.results) {
        message.success(`Category deleted successfully!`);
        const groups = this.groups.map(group => {
          if (group._id === record.group_id) {
            const categories = group.categories.filter(
              category => category._id !== record._id
            );
            group.categories = categories;
          }
          return group;
        });
        this.groups = groups;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  deleteSubCategory = async (e, record, groupId, categoryId) => {
    e.preventDefault();
    try {
      this.loading = true;
      let data = {
        subCategory: true, //send subCategory=true if user want to update sub-categories
        delete: true, //send delete=true if user want to delete sub-categories
        _id: record._id,
        property_id: this.globals.propertyId
      };
      const res = await ConfigureGroupsServices.updateCategory(
        data,
        groupId,
        categoryId
      );
      if (res && res.results) {
        message.success(`Sub-Category deleted successfully!`);
        const groups = this.groups.map(group => {
          if (group._id === groupId) {
            const categories = group.categories.map(category => {
              if (category._id === categoryId) {
                return res.results;
              }
              return category;
            });
            group.categories = categories;
          }
          return group;
        });
        this.groups = groups;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  deleteGroup = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const resp = await ConfigureGroupsServices.deleteGroup(
        this.globals.propertyId,
        record._id
      );
      if (resp && resp.results) {
        message.success("Group deleted successfully!");
        const groups = this.groups.filter(group => group._id !== record._id);
        this.groups = groups;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  getFulfillment = async () => {
    try {
      this.fulfillmentLoading = true;
      const res = await ConfigureGroupsServices.getFulfillment(
        this.globals.propertyId
      );
      if (res && res.results) {
        this.setFulfillment(res.results);
      } else message.error(res.message || "Something went wrong!", 5);
      this.fulfillmentLoading = false;
    } catch (error) {
      this.fulfillmentLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  setFulfillment = data => {
    data.forEach(element => {
      element.categoriesCopy = element.categories;
    });
    this.fulfillment = data;
  };
  openFulfillmentModal = (e, record) => {
    e.preventDefault();
    this.showFulfillmentModal = true;
    this.record = record;
  };
  handleFulfillmentSubmit = (e, form) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        this.fulfillmentLoading = true;
        console.log("Received values of form: ", values);
        try {
          if (!this.record) {
            //add fulfillment
            const res = await ConfigureGroupsServices.addFulfillment(
              values,
              this.globals.propertyId
            );
            if (res && res.results) {
              message.success("Fulfillment added successfully!");
              const fulfillment = this.fulfillment;
              fulfillment.push(res.results);
              this.setFulfillment(fulfillment);
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.fulfillmentLoading = false;
          } else {
            //edit fulfillment
            const res = await ConfigureGroupsServices.updateFulfillment(
              values,
              this.globals.propertyId,
              this.record._id
            );
            if (res && res.results) {
              message.success("Fulfillment updated successfully!");
              const fulfillment = this.fulfillment.map(ele => {
                if (ele._id === this.record._id) {
                  return res.results;
                }
                return ele;
              });
              this.setFulfillment(fulfillment);
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.fulfillmentLoading = false;
          }
        } catch (error) {
          this.fulfillmentLoading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };
  deleteFulfillment = async (e, record) => {
    e.preventDefault();
    try {
      this.fulfillmentLoading = true;
      const resp = await ConfigureGroupsServices.deleteFulfillment(
        this.globals.propertyId,
        record._id
      );
      if (resp && resp.results) {
        message.success("Fulfillment deleted successfully!");
        const fulfillment = this.fulfillment.filter(
          group => group._id !== record._id
        );
        this.setFulfillment(fulfillment);
      } else message.error(resp.message || "Something went wrong!", 5);
      this.fulfillmentLoading = false;
    } catch (error) {
      this.fulfillmentLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  getFulfillmentCategories = async () => {
    try {
      this.fulfillmentLoading = true;
      const res = await ConfigureGroupsServices.getFulfillmentCategories(
        this.globals.propertyId
      );
      if (res && res.results) {
        //set children to [] if emplty object
        res.results.map(ele => {
          if (ele.children.length && ele.children[0]._id) {
            return ele;
          }
          ele.children = [];
          return ele;
        });
        this.mapping = res.results;
      } else message.error(res.message || "Something went wrong!", 5);
      this.fulfillmentLoading = false;
    } catch (error) {
      this.fulfillmentLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  onChange = (value, fulfilmentId) => {
    let fulfillment = this.fulfillment.map(ele => {
      if (ele._id === fulfilmentId) {
        ele.categories = value;
        return ele;
      }
      return ele;
    });
    this.fulfillment = fulfillment;
  };
  updateFulfillmentCategory = async (data, fulfilmentId) => {
    const { categoriesCopy, categories } = data;
    let removedCategories = categoriesCopy.filter(
      //filter removed categories
      ele => !categories.includes(ele)
    );
    this.fulfillmentLoading = true;
    try {
      const res = await ConfigureGroupsServices.updateFulfillment(
        { categories, removedCategories },
        this.globals.propertyId,
        fulfilmentId
      );
      if (res && res.results) {
        message.success("Categories mapped to Fulfillment successfully!");
        this.getFulfillment();
      } else message.error(res.message || "Something went wrong!", 5);
      this.fulfillmentLoading = false;
    } catch (error) {
      this.fulfillmentLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default ConfigureGroupsStore;
