import RestfulProvider from "globals/restfulProvider/RestfulProvider";
import { serialize } from "utils/common";

class UserServices {
  getUsers = () => {
    return RestfulProvider.get(`users`);
  };
  updateUser = (data, userId) => {
    return RestfulProvider.put(`users/${userId}`, data);
  };
  addUser = data => {
    return RestfulProvider.post(`users`, data);
  };
  getUserData = data => {
    return RestfulProvider.get(`login?${serialize(data)}`);
  };
  deleteUser = userId => {
    return RestfulProvider.delete(`users/${userId}`);
  };
}
export default new UserServices();
