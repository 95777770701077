export default {
  hello: "Hello",
  profile: "Profile",
  resetPassword: "Reset Password",
  changeOrg: "Change Organisation",
  logout: "Logout",
  dashboard: "Dashboard",
  organisations: "Organisations",
  requests: "Requests",
  rooms: "Rooms",
  properties: "Properties",
  devices: "Devices",
  users: "Users",
  information: "Content Manager",
  noDataFound: "No data found",
  search: "Search",
  advertisements: "Advertisements",
  configureGroups: "Groups & Categories"
};
