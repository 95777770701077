import { observable } from "mobx";
import OrgServices from "services/OrgServices";
import { message, Modal } from "antd";

class RequestsStore {
  @observable allOrgs = [];
  @observable record = null;
  @observable showOrgModal = false;
  @observable selectedOrg = null;
  @observable btnLoading = false;

  constructor(globals) {
    this.globals = globals;
  }

  getOrgs = async () => {
    try {
      this.btnLoading = true;
      const res = await OrgServices.getOrgs();
      if (res && res.results) {
        this.allOrgs = res.results;
      } else message.error(res.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  openOrgModal = (e, record) => {
    e.preventDefault();
    this.showOrgModal = true;
    this.record = record;
  };

  handleCancel = form => {
    this.record = null;
    this.showOrgModal = false;
    form.resetFields();
  };

  onRadioChange = e => {
    this.selectedOrg = e.target.value;
  };

  selectOrg = (e, history) => {
    e.preventDefault();
    if (this.selectedOrg) {
      localStorage.clear();
      this.globals.resetPropertyId();
      let user = this.globals.cookies.get("user");
      user.org_id = this.selectedOrg; //ser org id to local user data for system admin
      this.globals.setCookies(user);
      history.push("requests");
      this.selectedOrg = null; //reset selected org
    } else {
      message.warning("Please select any organisation to continue!");
    }
  };

  handleOrgSubmit = form => {
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        try {
          this.btnLoading = true;
          let addresses = {
            street1: values.street1,
            street2: values.street2,
            city: values.city,
            country: values.country,
            postcode: values.postcode
          };
          if (this.record) {
            addresses._id = this.record.addresses && this.record.addresses._id;
          }
          values.addresses = addresses;
          if (!this.record) {
            //add Org
            const res = await OrgServices.addOrg(values);
            if (res && res.results) {
              message.success("Organisation added successfully!");
              let allOrgs = this.allOrgs;
              allOrgs.push(res.results);
              this.allOrgs = allOrgs;
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.btnLoading = false;
          } else {
            //edit Org
            values._id = this.record._id;
            const res = await OrgServices.updateOrg(values, this.record._id);
            if (res && res.results) {
              message.success("Organisation updated successfully!");
              let allOrgs = this.allOrgs.map(org => {
                if (org._id === this.record._id) {
                  return res.results;
                }
                return org;
              });
              this.allOrgs = allOrgs;
              this.handleCancel(form);
            } else message.error(res.message || "Something went wrong!", 5);
            this.btnLoading = false;
          }
        } catch (error) {
          this.btnLoading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };
  showConfirm = (e, record) => {
    Modal.confirm({
      title: "Are you sure?",
      content:
        "If you delete this Organisation then Property, Users, Devices, Groups, Service requests and Static contents under this Organisation will be deleted!",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        this.deleteOrg(e, record);
      }
    });
  };
  deleteOrg = async (e, record) => {
    e.preventDefault();
    try {
      this.btnLoading = true;
      const resp = await OrgServices.deleteOrg(record._id);
      if (resp && resp.results) {
        message.success("Organisation deleted successfully!");
        const allOrgs = this.allOrgs.filter(org => org._id !== record._id);
        this.allOrgs = allOrgs;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default RequestsStore;
