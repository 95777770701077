export default {
  hello: "Hallo",
  profile: "Profiel",
  resetPassword: "Reset wachtwoord",
  changeOrg: "Verander organisatie",
  logout: "Uitloggen",
  dashboard: "Dashboard",
  organisations: "organisaties",
  requests: "verzoeken",
  rooms: "kamers",
  properties: "eigenschappen",
  devices: "apparaten",
  users: "gebruikers",
  information: "Informatie",
  noDataFound: "Geen data gevonden",
  search: "Zoeken",
  advertisements: "advertenties",
  configureGroups: "Groepen en categorieën"
};
