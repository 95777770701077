export default {
  hello: "Hola",
  profile: "Perfil",
  resetPassword: "Restablecer la contraseña",
  changeOrg: "Cambiar organizacion",
  logout: "Cerrar sesión",
  dashboard: "Tablero",
  organisations: "Organizaciones",
  requests: "Peticiones",
  rooms: "Habitaciones",
  properties: "Propiedades",
  devices: "Dispositivos",
  users: "Usuarios",
  information: "Información",
  noDataFound: "Datos no encontrados",
  search: "Buscar",
  advertisements: "Anuncios",
  configureGroups: "Grupos y Categorias"
};
