import { observable } from "mobx";
import PropertyServices from "services/PropertyServices";
import { message, Modal } from "antd";

class RoomsStore {
  @observable allRooms = [];
  @observable loading = false;
  @observable record = null;
  @observable showRoomModal = false;
  @observable visible = false;

  constructor(globals) {
    this.globals = globals;
  }

  getRooms = async () => {
    if (this.globals.propertyId) {
      try {
        this.loading = true;
        const res = await PropertyServices.getRooms(this.globals.propertyId);
        if (res && res.results) {
          this.allRooms = res.results;
        } else message.error(res.message || "Something went wrong!", 5);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        message.error(
          (error.response && error.response.data) || error.message,
          5
        );
      }
    }
  };
  resetRooms = () => {
    this.allRooms = [];
  };
  openRoomModal = (e, record) => {
    e.preventDefault();
    this.showRoomModal = true;
    this.record = record;
  };
  showQRModal = record => {
    this.visible = true;
    this.record = record;
  };
  handleCancel = (e, form) => {
    this.record = null;
    this.showRoomModal = false;
    this.visible = false;
    form && form.resetFields();
  };

  handleRoomSubmit = (e, form) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        try {
          this.loading = true;
          if (!this.record) {
            let data = values.keys.map(key => {
              return {
                number: values.number[key],
                type: values.type[key]
              };
            });
            console.log(data);
            const resp = await PropertyServices.addRooms(
              { rooms: data },
              this.globals.propertyId
            );
            if (resp && resp.results) {
              message.success("Rooms added successfully!");
              this.allRooms = resp.results;
              this.handleCancel(e, form);
            } else message.error(resp.message || "Something went wrong!", 5);
            this.loading = false;
          } else {
            values.device_id = values.device_id || "";
            const resp = await PropertyServices.updateRoom(
              values,
              this.globals.propertyId,
              this.record._id
            );
            if (resp && resp.results) {
              message.success("Room details updated successfully!");
              this.allRooms = resp.results;
              this.handleCancel(e, form);
            } else message.error(resp.message || "Something went wrong!", 5);
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };
  showConfirm = (e, record) => {
    Modal.confirm({
      title: "Are you sure?",
      content:
        "If you delete this Room then Device and Service requests raised under this Room will be deleted!",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        this.deleteRoom(e, record);
      }
    });
  };
  deleteRoom = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const resp = await PropertyServices.deleteRoom(
        this.globals.propertyId,
        record._id
      );
      if (resp && resp.results) {
        message.success("Room deleted successfully!");
        const allRooms = this.allRooms.filter(room => room._id !== record._id);
        this.allRooms = allRooms;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  removeDevice = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const resp = await PropertyServices.updateRoom(
        { ...record, removeDevice: true },
        this.globals.propertyId,
        record._id
      );
      if (resp && resp.results) {
        message.success("Device removed successfully!");
        this.allRooms = resp.results;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  checkout = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const resp = await PropertyServices.updateRoom(
        { ...record, checkout: true },
        this.globals.propertyId,
        record._id
      );
      if (resp && resp.results) {
        message.success("checked out successfully!");
        this.allRooms = resp.results;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default RoomsStore;
