import RestfulProvider from "globals/restfulProvider/RestfulProvider";

class OrgServices {
  getOrgs = () => {
    return RestfulProvider.get(`orgs`);
  };
  updateOrg = (data, orgId) => {
    return RestfulProvider.put(`orgs/${orgId}`, data);
  };
  addOrg = data => {
    return RestfulProvider.post(`orgs`, data);
  };
  getOrg = orgId => {
    return RestfulProvider.get(`orgs/${orgId}`);
  };
  deleteOrg = orgId => {
    return RestfulProvider.delete(`orgs/${orgId}`);
  };
}
export default new OrgServices();
