import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import Stores from "./globals/CombinedStore";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MobxIntlProvider } from "mobx-react-intl";
import { addLocaleData } from "react-intl";
import enLocale from "react-intl/locale-data/en";
import esLocale from "react-intl/locale-data/es";
import nlLocale from "react-intl/locale-data/nl";
import { toJS } from "mobx";
addLocaleData([...enLocale, ...esLocale, ...nlLocale]);

ReactDOM.render(
  <Provider {...Stores}>
    <MobxIntlProvider>
      <App />
    </MobxIntlProvider>
  </Provider>,
  document.getElementById("root")
);
window.console = (function(oldCons) {
  return {
    log: function(...args) {
      const newArgs = args.map(param => {
        return toJS(param);
      });
      oldCons.log(...newArgs);
    },
    info: function(text) {
      // oldCons.info(text);
      // Your code
    },
    warn: function(text) {
      // oldCons.warn(text);
      // Your code
    },
    error: function(text) {
      // oldCons.error(text);
      // Your code
    }
  };
})(window.console);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
