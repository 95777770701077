import { observable } from "mobx";
import UserServices from "services/UserServices";
import { message } from "antd";

class ProfileStore {
  @observable loading = false;
  @observable fileList = [];
  @observable imgLoading = false;

  constructor(globals) {
    this.globals = globals;
  }

  handleSubmit = (e, form) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.loading = true;
        try {
          let name = {
            first_name: values.first_name,
            last_name: values.last_name,
            display_name: values.display_name,
            _id: this.globals.user.name && this.globals.user.name._id
          };
          const resp = await UserServices.updateUser(
            { name },
            this.globals.user._id
          );
          if (resp && resp.results) {
            message.success("Profile updated successfully!");
            this.globals.updateUser(resp.results);
          } else message.error(resp.message || "Something went wrong!", 5);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };
  handleUpload = async ({ fileList }) => {
    this.imgLoading = true;
    let files = fileList.map(file => {
      file.status = "done";
      return file;
    });
    this.fileList = files;
    const formData = new FormData();
    if (this.fileList.length) {
      for (const file of this.fileList) {
        formData.append("profile_image", file.originFileObj);
      }
    }
    try {
      const resp = await UserServices.updateUser(
        formData,
        this.globals.user._id
      );
      if (resp && resp.results) {
        message.success("Profile image uploaded successfully!");
        this.globals.updateUser(resp.results);
      } else {
        this.fileList = [];
        message.error(resp.message || "Something went wrong!", 5);
      }
      this.imgLoading = false;
    } catch (error) {
      this.fileList = [];
      this.imgLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  handleRemove = async () => {
    try {
      const resp = await UserServices.updateUser(
        { profile_image: "" },
        this.globals.user._id
      );
      if (resp && resp.results) {
        message.success("Profile image removed successfully!");
        this.fileList = [];
        this.globals.updateUser(resp.results);
      } else message.error(resp.message || "Something went wrong!", 5);
      this.imgLoading = false;
    } catch (error) {
      this.imgLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default ProfileStore;
