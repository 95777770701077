import RestfulProvider from "globals/restfulProvider/RestfulProvider";
import { serialize } from "utils/common";

class RequestServices {
  getRequests = data => {
    return RestfulProvider.get(`requests?${serialize(data)}`);
  };
  updateRequest = (data, requestId) => {
    return RestfulProvider.put(`requests/${requestId}`, data);
  };
  deleteRequest = requestId => {
    return RestfulProvider.delete(`requests/${requestId}`);
  };
  getCategoryOfProperty = propertyId => {
    return RestfulProvider.get(`categories/property/${propertyId}`);
  };
}
export default new RequestServices();
