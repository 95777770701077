import RestfulProvider from "globals/restfulProvider/RestfulProvider";

class PropertyServices {
  getProperties = () => {
    return RestfulProvider.get(`properties`);
  };
  updateProperty = (data, propertyId) => {
    return RestfulProvider.put(`properties/${propertyId}`, data);
  };
  addProperty = data => {
    return RestfulProvider.post(`properties`, data);
  };
  getRooms = propertyId => {
    return RestfulProvider.get(`properties/${propertyId}/rooms`);
  };
  updateRoom = (data, propertyId, roomId) => {
    return RestfulProvider.put(
      `properties/${propertyId}/rooms/${roomId}`,
      data
    );
  };
  addRooms = (data, propertyId) => {
    return RestfulProvider.post(`properties/${propertyId}/rooms`, data);
  };
  deleteRoom = (propertyId, roomId) => {
    return RestfulProvider.delete(`properties/${propertyId}/rooms/${roomId}`);
  };
  deleteProperty = propertyId => {
    return RestfulProvider.delete(`properties/${propertyId}`);
  };
}
export default new PropertyServices();
