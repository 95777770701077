export default {
  ROLES: {
    MANAGER: "manager",
    ADMIN: "admin",
    REQUEST_MANAGER: "request_manager",
    SITE_MANAGER: "site_manager"
  },
  STATUS: {
    IN_PROGRESS: "in_progress",
    COMPLETED: "completed",
    PENDING: "pending",
    OPEN: "open"
  },
  PRIORITY: {
    LOW: "low",
    MEDIUM: "medium",
    NORMAL: "normal",
    HIGH: "high"
  },
  FULFILMENT_TYPE: {
    REQUEST: "request",
    CONTENT: "content"
  },
  AD_TYPE: {
    INT: "int",
    EXT: "ext"
  },
  SMILE_RATING: {
    SMILE: 0,
    MEH: 1,
    FROWN: 3
  }
};
