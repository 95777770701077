import RestfulProvider from "globals/restfulProvider/RestfulProvider";
import { serialize } from "utils/common";

class ConfigureGroupsServices {
  updateGroup = (data, propertyId, groupId) => {
    return RestfulProvider.put(`groups/${propertyId}/${groupId}`, data);
  };
  getGroups = (propertyId, data) => {
    return RestfulProvider.get(`groups/${propertyId}?${serialize(data)}`);
  };
  addGroup = (data, propertyId) => {
    return RestfulProvider.post(`groups/${propertyId}`, data);
  };
  updateCategory = (data, groupId, categoryId) => {
    return RestfulProvider.put(`categories/${groupId}/${categoryId}`, data);
  };
  addCategory = (data, groupId) => {
    return RestfulProvider.post(`categories/${groupId}`, data);
  };
  deleteCategory = (groupId, categoryId) => {
    return RestfulProvider.delete(`categories/${groupId}/${categoryId}`);
  };
  deleteGroup = (propertyId, groupId) => {
    return RestfulProvider.delete(`groups/${propertyId}/${groupId}`);
  };
  getFulfillment = propertyId => {
    return RestfulProvider.get(`fulfillment/${propertyId}`);
  };
  addFulfillment = (data, propertyId) => {
    return RestfulProvider.post(`fulfillment/${propertyId}`, data);
  };
  updateFulfillment = (data, propertyId, fulfillmentId) => {
    return RestfulProvider.put(
      `fulfillment/${propertyId}/${fulfillmentId}`,
      data
    );
  };
  deleteFulfillment = (propertyId, fulfillmentId) => {
    return RestfulProvider.delete(`fulfillment/${propertyId}/${fulfillmentId}`);
  };
  getFulfillmentCategories = propertyId => {
    return RestfulProvider.get(`categories/property/${propertyId}/fulfillment`);
  };
}
export default new ConfigureGroupsServices();
