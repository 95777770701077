import { observable } from "mobx";
import UserServices from "services/UserServices";
import { message } from "antd";

class RequestsStore {
  @observable allUsers = [];
  @observable loading = false;
  @observable record = null;
  @observable showUserModal = false;

  constructor(globals, firebase) {
    this.globals = globals;
    this.firebase = firebase;
  }

  getUsers = async () => {
    try {
      this.loading = true;
      const res = await UserServices.getUsers();
      if (res && res.results) {
        this.allUsers = res.results;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  openUserModal = (e, record) => {
    e.preventDefault();
    this.showUserModal = true;
    this.record = record;
  };

  handleCancel = form => {
    this.record = null;
    this.showUserModal = false;
    form.resetFields();
  };

  handleUserSubmit = form => {
    form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const { email, password } = values;
        try {
          this.loading = true;
          if (!this.record) {
            const res = await this.firebase.doCreateUserWithEmailAndPassword(
              email,
              password
            );
            if (res && res.user) {
              console.log(res.user.uid);
              values.auth_uid = res.user.uid;
              values.name = {
                first_name: values.first_name,
                last_name: values.last_name,
                display_name: values.display_name
              };
              console.log("values", values);
              const resp = await UserServices.addUser(values);
              if (resp && resp.results) {
                message.success("User added successfully!");
                const allUsers = this.allUsers;
                allUsers.push(resp.results);
                this.allUsers = allUsers;
                this.handleCancel(form);
              } else message.error(resp.message || "Something went wrong!", 5);
            } else message.error(res.message || "Something went wrong!", 5);
            this.loading = false;
          } else {
            values.name = {
              first_name: values.first_name,
              last_name: values.last_name,
              display_name: values.display_name,
              _id: this.record.name && this.record.name._id
            };
            const resp = await UserServices.updateUser(values, this.record._id);
            if (resp && resp.results) {
              message.success("User details updated successfully!");
              const allUsers = this.allUsers.map(user => {
                if (user._id === this.record._id) {
                  user = resp.results;
                }
                return user;
              });
              this.allUsers = allUsers;
              this.handleCancel(form);
            } else message.error(resp.message || "Something went wrong!", 5);
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };

  deleteUser = async (e, record) => {
    e.preventDefault();
    try {
      this.loading = true;
      const resp = await UserServices.deleteUser(record._id);
      if (resp && resp.results) {
        message.success("User deleted successfully!");
        const allUsers = this.allUsers.filter(user => user._id !== record._id);
        this.allUsers = allUsers;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default RequestsStore;
