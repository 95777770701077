import GlobalStore from "./stores/GlobalStore";
import ConfigureGroupsStore from "./stores/ConfigureGroupsStore";
import FirebaseStore from "./stores/FirebaseStore";
import PropertiesStore from "./stores/PropertiesStore";
import RequestsStore from "./stores/RequestsStore";
import UsersStore from "./stores/UsersStore";
import OrgsStore from "./stores/OrgsStore";
import RoomsStore from "./stores/RoomsStore";
import InformationStore from "./stores/InformationStore";
import LocaleStore from "./stores/LocaleStore";
import AdvertisementsStore from "./stores/AdvertisementsStore";
import ProfileStore from "./stores/ProfileStore";

import en from "./translations/en";
import nl from "./translations/nl";
import es from "./translations/es";

const locale = new LocaleStore("en", {
  en,
  nl,
  es
});
const globals = new GlobalStore(locale);
const firebase = new FirebaseStore();

export default {
  globals,
  firebase,
  locale,
  configureGroups: new ConfigureGroupsStore(globals),
  properties: new PropertiesStore(globals),
  requests: new RequestsStore(globals),
  users: new UsersStore(globals, firebase),
  orgs: new OrgsStore(globals),
  rooms: new RoomsStore(globals),
  information: new InformationStore(globals),
  advertisements: new AdvertisementsStore(globals),
  profile: new ProfileStore(globals)
};
