import { observable } from "mobx";
import PropertyServices from "services/PropertyServices";
import { message, Modal } from "antd";

class PropertiesStore {
  @observable showPropertyModal = false;
  @observable record = {
    addresses: {},
    settings: { tablet_app_settings: {}, mobile_app_settings: {} }
  };
  @observable imageUrl = "";
  @observable rooms = [];
  @observable roomType = [];
  @observable tabletImageUrl = "";
  @observable mobileImageUrl = "";
  @observable btnLoading = false;
  @observable mobileLogoUrl = "";
  @observable tabletLogoUrl = "";

  constructor(globals) {
    this.globals = globals;
  }

  openPropertyModal = (e, record) => {
    e.preventDefault();
    this.showPropertyModal = true;
    // record &&
    //   record.rooms.forEach((data, index) => {
    //     data.key = index.toString();
    //   });
    record &&
      record.roomType.forEach((data, index) => {
        data.key = index.toString();
      });
    this.record = record || {
      addresses: {},
      settings: { tablet_app_settings: {}, mobile_app_settings: {} }
    };
    // this.rooms =
    //   (record &&
    //     record.rooms.filter(ele => ele.number !== "" && ele.type !== "")) ||
    //   [];
    this.roomType =
      (record && record.roomType.filter(ele => ele.type !== "")) || [];
  };

  setRooms = rooms => {
    this.rooms = rooms;
  };
  setRoomType = roomType => {
    this.roomType = roomType;
  };
  handleCancel = form => {
    this.record = {
      addresses: {},
      settings: { tablet_app_settings: {}, mobile_app_settings: {} }
    };
    this.showPropertyModal = false;
    form.resetFields();
    this.imageUrl = "";
    this.tabletImageUrl = "";
    this.mobileImageUrl = "";
    this.mobileLogoUrl = "";
    this.tabletLogoUrl = "";
    this.rooms = [];
    this.roomType = [];
  };

  getBase64(img, callback) {
    const reader = new window.FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleImageChange = (info, type) => {
    this.getBase64(info, imageUrl => {
      if (type === "image") {
        this.imageUrl = imageUrl;
      } else if (type === "tabletImage") {
        this.tabletImageUrl = imageUrl;
      } else if (type === "mobileImage") {
        this.mobileImageUrl = imageUrl;
      } else if (type === "tabletLogo") {
        this.tabletLogoUrl = imageUrl;
      } else if (type === "mobileLogo") {
        this.mobileLogoUrl = imageUrl;
      }
    });
    return false;
  };

  handlePropertySubmit = form => {
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.btnLoading = true;
        console.log(
          "Received values of form: ",
          values,
          this.rooms,
          this.roomType
        );
        const formData = new window.FormData();
        try {
          //property addresses
          let addresses = {
            street1: values.street1,
            street2: values.street2,
            city: values.city,
            country: values.country,
            postcode: values.postcode
          };
          if (this.record) {
            addresses._id = this.record.addresses && this.record.addresses._id;
          }

          //property settings
          let settings = {
            lang: values.lang && values.lang.join(","),
            currency: values.currency,
            tablet_app_settings: {
              enabled: values.tablet_enabled,
              languages: values.tablet_lang
            },
            mobile_app_settings: {
              enabled: values.mobile_enabled,
              languages: values.mobile_lang
            }
          };

          //property image
          if (values.image) {
            formData.append("image", values.image.file);
          } else {
            this.record &&
              this.record.image &&
              formData.append("image", this.record.image);
          }

          //tablet image
          if (values.tablet_image) {
            formData.append("tablet_image", values.tablet_image.file);
          } else if (
            this.record &&
            this.record.settings.tablet_app_settings.background_img
          ) {
            settings.tablet_app_settings.background_img = this.record.settings.tablet_app_settings.background_img;
          }

          //tablet logo
          if (values.tablet_logo) {
            formData.append("tablet_logo", values.tablet_logo.file);
          } else if (
            this.record &&
            this.record.settings.tablet_app_settings.logo_img
          ) {
            settings.tablet_app_settings.logo_img = this.record.settings.tablet_app_settings.logo_img;
          }

          //mobile image
          if (values.mobile_image) {
            formData.append("mobile_image", values.mobile_image.file);
          } else if (
            this.record &&
            this.record.settings.mobile_app_settings.background_img
          ) {
            settings.mobile_app_settings.background_img = this.record.settings.mobile_app_settings.background_img;
          }

          //mobile logo
          if (values.mobile_logo) {
            formData.append("mobile_logo", values.mobile_logo.file);
          } else if (
            this.record &&
            this.record.settings.mobile_app_settings.logo_img
          ) {
            settings.mobile_app_settings.logo_img = this.record.settings.mobile_app_settings.logo_img;
          }

          if (this.record && this.record.settings) {
            settings._id = this.record.settings._id;
            settings.tablet_app_settings._id = this.record.settings.tablet_app_settings._id;
            settings.mobile_app_settings._id = this.record.settings.mobile_app_settings._id;
          }

          values.name && formData.append("name", values.name);
          values.property_id &&
            formData.append("property_id", values.property_id);
          values.phone && formData.append("phone", values.phone);
          values.location && formData.append("location", values.location);
          values.capacity && formData.append("capacity", values.capacity);
          values.star && formData.append("star", values.star);
          values.property_code &&
            formData.append("property_code", values.property_code);
          // formData.append("rooms", JSON.stringify(this.rooms));
          formData.append("roomType", JSON.stringify(this.roomType));
          formData.append("addresses", JSON.stringify(addresses));
          formData.append("settings", JSON.stringify(settings));

          if (!this.record._id) {
            //add Property
            const res = await PropertyServices.addProperty(formData);
            if (res && res.results) {
              message.success("Property added successfully!");
              this.handleCancel(form);
              this.globals.updateProperties(res.results);
            } else message.error(res.message || "Something went wrong!", 5);
            this.btnLoading = false;
          } else {
            //edit Property
            const res = await PropertyServices.updateProperty(
              formData,
              this.record._id
            );
            if (res && res.results) {
              message.success("Property updated successfully!");
              this.handleCancel(form);
              this.globals.updateProperties(res.results);
            } else message.error(res.message || "Something went wrong!", 5);
            this.btnLoading = false;
          }
        } catch (error) {
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
          this.btnLoading = false;
        }
      }
    });
  };
  showConfirm = (e, record) => {
    Modal.confirm({
      title: "Are you sure?",
      content:
        "If you delete this Property then Devices, Groups, Service requests and Static contents under this Property will be deleted!",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        this.deleteProperty(e, record);
      }
    });
  };
  deleteProperty = async (e, record) => {
    e.preventDefault();
    try {
      this.btnLoading = true;
      const resp = await PropertyServices.deleteProperty(record._id);
      if (resp && resp.results) {
        message.success("Property deleted successfully!");
        const data = this.globals.properties.filter(
          property => property._id !== record._id
        );
        this.globals.setDeleteProperties(data, record._id);
      } else message.error(resp.message || "Something went wrong!", 5);
      this.btnLoading = false;
    } catch (error) {
      this.btnLoading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  onPropertyLangChange = form => {
    form.setFieldsValue({ tablet_lang: undefined, mobile_lang: undefined });
  };
}

export default PropertiesStore;
