import { observable } from "mobx";
import PropertyServices from "services/PropertyServices";
import OrgServices from "services/OrgServices";
import { Cookies } from "react-cookie";
import UserServices from "services/UserServices";
import { message } from "antd";
import constants from '../../utils/constants'
class GlobalStore {
  @observable isLoggedIn = false;
  @observable cookies = new Cookies();
  @observable loading = false;
  @observable properties = [];
  @observable propertyId = null;
  @observable propertyData = [];
  @observable user = null;
  @observable orgData = null;

  constructor(locale) {
    this.locale = locale;
    if (this.cookies.get("user")) {
      this.isLoggedIn = true;
      this.user = this.cookies.get("user");
      this.user.org_id && this.getOrgData(this.user.org_id);
      this.locale.value = this.user.locale;
    }
    this.propertyId = localStorage.getItem("selectedProperty");
  }

  getProperties = async () => {
    try {
      const res = await PropertyServices.getProperties();
      if (res && res.results) {
        this.properties = res.results;
        if (this.user.role === constants.ROLES.REQUEST_MANAGER || this.user.role === constants.ROLES.SITE_MANAGER){
          this.changeProperty(this.user.property_id[0]);
        }
        else if (this.propertyId) {
          this.changeProperty(this.propertyId);
        } else {
          let id = res.results.length && res.results[0]._id;
          id && this.changeProperty(id);
        }
      } else message.error(res.message || "Something went wrong!", 5);
    } catch (error) {
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  getOrgData = async orgId => {
    try {
      const res = await OrgServices.getOrg(orgId);
      if (res && res.results) {
        this.orgData = res.results;
      } else message.error(res.message || "Something went wrong!", 5);
    } catch (error) {
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  changeProperty = value => {
    //update selected property id
    this.propertyId = value;
    localStorage.setItem("selectedProperty", value);
    //update property data
    let propertyData =
      this.properties.filter(property => property._id === value) || [];
    this.propertyData = propertyData;
  };

  updateProperties = data => {
    let properties = this.properties;
    let index = properties.findIndex(property => property._id === data._id);
    if (index > -1) {
      properties.splice(index, 1, data);
    } else {
      properties.push(data);
    }
    this.properties = [...properties];

    //update selected property data
    if (this.propertyId === data._id) {
      let propertyData = [];
      propertyData.push(data);
      this.propertyData = propertyData;
    }
  };

  setDeleteProperties = (data, id) => {
    this.properties = [...data];
    //update selected property data
    if (this.propertyId === id) {
      let propertyData = [];
      propertyData.push(this.properties[0]);
      this.propertyData = this.properties.length ? propertyData : [];
      this.propertyId = this.propertyData.length
        ? this.propertyData[0]._id
        : null;
    }
  };
  updateUser = user => {
    user.org_id = this.user.org_id; //set org data
    this.cookies.set("user", user);
    this.user = user;
  };
  setCookies = async (data, cb) => {
    await this.cookies.set("user", data);
    this.user = data;
    this.user.org_id && this.getOrgData(this.user.org_id);
    this.locale.value = this.user.locale;
    cb && cb();
  };
  removeCookies = () => {
    this.cookies.remove("user");
    this.user = null;
    this.loading = false;
    this.properties = [];
    this.propertyId = null;
    this.propertyData = [];
    this.orgData = null;
    localStorage.clear();
  };
  resetPropertyId = () => {
    this.propertyId = null;
  };
  setLogin = (value, cb) => {
    this.isLoggedIn = value;
    cb && cb();
  };
  onLangChange = async ({ key }) => {
    try {
      const resp = await UserServices.updateUser(
        { locale: key },
        this.user._id
      );
      if (resp && resp.results) {
        message.success("Language updated successfully");
        let user = this.cookies.get("user");
        user.locale = key;
        this.setCookies(user);
      } else message.error(resp.message || "Something went wrong!", 5);
    } catch (error) {
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default GlobalStore;
