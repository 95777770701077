import { observable } from "mobx";
import RequestServices from "services/RequestServices";
import { message } from "antd";
import constants from "utils/constants";
import moment from "moment";

class RequestsStore {
  @observable allRequests = [];
  @observable loading = false;
  @observable btnLoading = false;
  @observable record = null;
  @observable showRequestModal = false;
  @observable inlinePriority = null;
  @observable inlineStatus = null;
  @observable categories = [];
  @observable subCategories = [];
  @observable showRatingsModal = false;
  @observable tableSettings = {
    position: "bottom",
    showQuickJumper: true,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    total: 0,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
  };

  constructor(globals) {
    this.globals = globals;
  }

  getRequests = async params => {
    try {
      this.loading = true;
      const res = await RequestServices.getRequests(params);
      if (res && res.data) {
        let tableSettings = { ...this.tableSettings };
        tableSettings.total = res.data.count;
        tableSettings.pageSize = res.data.pageSize || 20;
        tableSettings.current = res.data.page + 1;
        this.tableSettings = tableSettings;
        this.allRequests = res.data.results;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };

  onTrackFilter = (pagination, filters, sorter) => {
    let params = {
      page: pagination.current - 1,
      pageSize: pagination.pageSize,
      order: -1,
      orderBy: "created_at",
      property_id: this.globals.propertyId
    };
    if (sorter.field) {
      params.orderBy = sorter.field;
      params.order = sorter.order === "descend" ? -1 : 1;
    }
    if (filters.status && filters.status.length) {
      params.status = JSON.stringify(filters.status);
    }
    if (filters.priority && filters.priority.length) {
      params.priority = JSON.stringify(filters.priority);
    }
    if (filters.star_rating && filters.star_rating.length) {
      params.star_rating = JSON.stringify(filters.star_rating.map(Number));
    }
    this.getRequests(params);
  };

  getCategoryOfProperty = async record => {
    //if property id is different then fetch new list of categories for that property id
    if (
      (this.categories.length && this.categories[0].property_id) !==
      record.property_id
    ) {
      try {
        this.loading = true;
        const res = await RequestServices.getCategoryOfProperty(
          record.property_id
        );
        if (res && res.results) {
          //filter out categories which are having fulfilment type "request" or having sub categories having fulfilment type "request"
          let categories = res.results.filter(cat => {
            if (cat.fulfilment_type === constants.FULFILMENT_TYPE.REQUEST) {
              return true;
            } else {
              return cat.sub_categories.some(
                sub => sub.fulfilment_type === constants.FULFILMENT_TYPE.REQUEST
              );
            }
          });
          this.categories = categories;
          this.onCategorySelect(record.payload.category_name);
        } else message.error(res.message || "Something went wrong!", 5);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        message.error(
          (error.response && error.response.data) || error.message,
          5
        );
      }
    }
  };

  onCategorySelect = (value, form) => {
    //fetch new sub-categories list
    let filteredCategory = this.categories.filter(
      category => category.title === value
    );
    //filter our sub categories having fulfilemnt type "request"
    let subCategories =
      (filteredCategory.length &&
        filteredCategory[0].sub_categories.filter(
          sub => sub.fulfilment_type === constants.FULFILMENT_TYPE.REQUEST
        )) ||
      [];
    this.subCategories = subCategories;

    //reset selected sub-category
    form && form.setFieldsValue({ sub_category: undefined });
  };

  openRequestModal = (e, record) => {
    e.preventDefault();
    this.record = record;
    this.showRequestModal = true;
  };
  openRatingsModal = (e, record) => {
    e.stopPropagation();
    this.record = record;
    this.showRatingsModal = true;
  };
  handleCancel = form => {
    this.record = null;
    this.showRequestModal = false;
    this.showRatingsModal = false;
    form && form.resetFields();
  };
  resetRequestData = () => {
    this.allRequests = [];
    this.tableSettings = {
      position: "bottom",
      showQuickJumper: true,
      pageSize: 20,
      defaultCurrent: 1,
      current: 1,
      total: 0,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    };
  };
  handleRequestSubmit = form => {
    form.validateFields(async (err, values) => {
      if (!err) {
        values.date = moment(values.date).toISOString();
        console.log("Received values of form: ", values);
        values.payload = {
          ...this.record.payload,
          category_name: values.category,
          sub_category_name: values.sub_category,
          request_for_date: values.date
        };
        try {
          this.btnLoading = true;
          const res = await RequestServices.updateRequest(
            values,
            this.record._id
          );
          if (res && res.results) {
            message.success("Service request updated successfully!");
            const allRequests = this.allRequests.map(request => {
              if (request._id === this.record._id) {
                return { ...this.record, ...res.results };
              }
              return request;
            });
            this.allRequests = allRequests;
            this.handleCancel(form);
          } else message.error(res.message || "Something went wrong!", 5);
          this.btnLoading = false;
        } catch (error) {
          this.btnLoading = false;
          message.error(
            (error.response && error.response.data) || error.message,
            5
          );
        }
      }
    });
  };

  editRow = (e, record) => {
    e.stopPropagation();
    let allRequests = [...this.allRequests];
    allRequests.forEach(req => {
      if (req._id === record._id) {
        req.editable = true;
      } else {
        req.editable = false;
      }
    });
    this.allRequests = allRequests;
    this.inlinePriority = record.priority;
    this.inlineStatus = record.status;
  };

  closeRow = (e, record) => {
    e.stopPropagation();
    let allRequests = [...this.allRequests];
    allRequests.forEach(req => {
      if (req._id === record._id) {
        req.editable = false;
      }
    });
    this.allRequests = allRequests;
    this.inlinePriority = null;
    this.inlineStatus = null;
  };
  onPriorityChange = value => {
    this.inlinePriority = value;
  };
  onStatusChange = value => {
    this.inlineStatus = value;
  };
  saveRow = async (e, record) => {
    e.stopPropagation();
    let data = {};
    if (this.inlinePriority) {
      data.priority = this.inlinePriority;
    }
    if (this.inlineStatus) {
      data.status = this.inlineStatus;
    }
    try {
      this.loading = true;
      const res = await RequestServices.updateRequest(data, record._id);
      if (res && res.results) {
        message.success("Service request updated successfully!");
        const allRequests = this.allRequests.map(request => {
          if (request._id === record._id) {
            return { ...res.results, ratings: record.ratings };
          }
          return request;
        });
        this.allRequests = allRequests;
        this.inlinePriority = null;
        this.inlineStatus = null;
      } else message.error(res.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
  deleteRequest = async (e, record) => {
    e.stopPropagation();
    try {
      this.loading = true;
      const resp = await RequestServices.deleteRequest(record._id);
      if (resp && resp.results) {
        message.success("Request deleted successfully!");
        const allRequests = this.allRequests.filter(
          req => req._id !== record._id
        );
        let tableSettings = { ...this.tableSettings };
        tableSettings.total = tableSettings.total - 1;
        this.tableSettings = tableSettings;
        this.allRequests = allRequests;
      } else message.error(resp.message || "Something went wrong!", 5);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      message.error(
        (error.response && error.response.data) || error.message,
        5
      );
    }
  };
}

export default RequestsStore;
